<script>

import axios from 'axios'

export default {

  props: {
    edit_url: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      editUrl: this.edit_url,
      editMode: false,
      editFormLoaded: false,
      editHtml: null
    }
  },

  methods: {
    toggle(url) {
      if (url) {
        this.editUrl = url
      }
      this.editMode = !this.editMode
    }
  },

  watch: {
    editMode() {
      if (this.editMode) {
        axios.get(this.editUrl).then((response)=>{
          this.editHtml = response.data
        })
      }
    }
  }

}
</script>

<template>
  <div>
    <div v-if="editMode" v-html="editHtml"></div>
    <div v-else>
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss">

</style>