// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import Vue from 'vue'
import Buefy from 'buefy'
Vue.use(Buefy)
Vue.config.ignoredElements = ['action-text-attachment', 'trix-editor']

import * as Trix from "trix"
require("@rails/actiontext")

window.Vue = Vue
window.bus = new Vue()

import vueCustomElement from 'vue-custom-element'
import FlashNotification from '../lib/FlashNotification'
import AudioPlayer from '../lib/AudioPlayer.vue'
import GuitarTab from '../lib/GuitarTab.vue'
import PlaybackPosition from '../lib/PlaybackPosition.vue'
import InlinePreview from '../lib/InlinePreview.vue'
import InlineEdit from '../lib/InlineEdit.vue'
import CommentActions from '../lib/CommentActions.vue'

document.addEventListener('DOMContentLoaded', () => {
  Vue.use(vueCustomElement);
  Vue.customElement('flash-notification', FlashNotification)
  Vue.customElement('audio-player', AudioPlayer)
  Vue.customElement('playback-position', PlaybackPosition)
  Vue.customElement('inline-preview', InlinePreview)
  Vue.customElement('inline-edit', InlineEdit)
  Vue.customElement('comment-actions', CommentActions)
  Vue.customElement('guitar-tab', GuitarTab)
})


document.addEventListener('DOMContentLoaded', () => {

  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {

    // Add a click event on each of them
    $navbarBurgers.forEach( el => {
      el.addEventListener('click', () => {

        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');

      });
    });
  }
});
