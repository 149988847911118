<script>
export default {
  props: {
    url: {},
    options: {}
  },

  methods: {
    openTab() {
      window.bus.$emit('guitartab.open', {
        url: this.url,
        blob_sgid: this.options.blob_sgid,
        name: this.options.filename
      })
    }
  }
}
</script>

<template>

  <!-- <b-button @click="openTab" icon-right="guitar-pick"> -->
  <b-button tag="a" :href="url" target="_blank" icon-right="guitar-pick">
    {{ options.filename }} &middot; {{ options.label }}
  </b-button>

</template>

<style lang="scss">
</style>