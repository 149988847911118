<script>
export default {
  props: {
    comment_id: {},
    update_url: {type: String},
    edit: { type: Boolean },
    pinned: { type: Boolean }
  },

  methods: {
    triggerEdit() {
      this.$el.closest('.comment').querySelector('inline-edit').getVueInstance().toggle()
    }
  },

  computed: {
    authenticityToken() {
      return document.head.querySelector('[name="csrf-token"]').content
    },

    pinIcon() {
      return this.pinned ? 'pin-off' : 'pin'
    }
  }
}
</script>

<template>
  <div class="comment-actions buttons">

    <form :action="update_url" method="POST">
      <input type="hidden" name="authenticity_token" :value="authenticityToken"/>
      <input type="hidden" name="_method" value="PATCH"/>
      <input type="hidden" name="toggle_pin" value="1"/>
      <input type="hidden" name="comment[pinned]" :value="!pinned"/>
      <b-button native-type="submit" :icon-left="pinIcon" type="is-text"></b-button>
    </form>

    <b-button v-if="edit" @click="triggerEdit" icon-left="pen" type="is-text"></b-button>
  </div>
</template>

<style lang="scss">
.comment-actions {
  &, .mdi:before {
    transition: color ease 0.2s;
    color: #ccc;
  }
  &:hover {
    &, .mdi:before {
      color: #222;
    }
  }
}
</style>