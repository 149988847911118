export default {
  props: [
    'kind', 'value'
  ],
  mounted() {
    this.$buefy.toast.open({
        duration: 5000,
        message: this.value,
        position: 'is-bottom',
        type: this.toastType
    })
  },
  computed: {
    toastType() {
      return {
        error: 'is-danger',
        notice: 'is-primary',
        success: 'is-success'
      }[this.kind]
    }
  },
  render(){}
}