<script>

import axios from 'axios'
import WaveSurfer from "wavesurfer.js"
import Regions from "wavesurfer.js/dist/plugin/wavesurfer.regions.js"
import {throttle} from 'lodash'
import EditAudioMark from './EditAudioMark.vue'
import Keyboard from './Keyboard.js'

export default {

  mixins: [Keyboard],

  components: {
    EditAudioMark
  },

  data() {
    return {
      blobSgid: null,
      blobUrl: null,
      blobName: null,

      pendingSelectedRegion: null,
      currentlyPlayedRegion: null,
      selectedAudioMark: null,

      wavesurfer: null,
      isPlaying: false,
      fileReady: false,
      loopRegion: false,
      loopCurrentlyPlayedRegion: false,

      currentSeconds: 0,
      duration: 0,
      clientRegionId: 0,
      onLoadOptions: {},

      audioMarks: [],
      audioMarkIsLoading: false,
      colorPalette: ['#2f7ed866', '#0d233a66', '#8bbc2166', '#91000066', '#1aadce66', '#49297066', '#f28f4366', '#77a1e566', '#c4252566', '#a6c96a66'],

      showRegions: true,
      showRegionEdits: false
    }
  },

  mounted() {
    this.showRegionEdits = !this.isTouchDevice

    window.bus.$on('audio.play', (item)=>{
      if (!this.wavesurfer) { this.createWaveSurfer() }
      this.pause()
      let noUrlChange = this.blobUrl == item.url

      this.blobUrl = null
      this.blobSgid = item.blob_sgid
      this.blobName = item.name
      this.blobUrl = item.url
      this.onLoadOptions = {
        start_seconds: item.start_seconds,
        end_seconds: item.end_seconds
      }

      if (noUrlChange) {
        this.applyOnLoadOptions()
      }
    })
  },

  keyboard: {
    if() {
      return !!this.wavesurfer && (!document.activeElement || document.activeElement.tagName == 'BODY')
    },
    down: {
      space(event) { event.preventDefault() }
    },
    up: {
      space() { this.playPause() },
      left()  { this.rewind(5) },
      right() { this.fastForward(5) },
      enter() { this.createRegion() }
    },
  },

  watch: {
    showRegions() {
      this.wavesurfer.regions.clear()
      this.clientRegionId = 0
      if (this.showRegions) {
        for(let audioMark of this.audioMarks) {
          this.addAudioMark(audioMark)
        }
      }
    },

    audioMarks() {
      this.$emit('audio-marks-loaded', this.audioMarks)
    },

    blobSgid() {
      if (!this.blobSgid) { return }

      axios.get(`/audio_marks/${this.blobSgid}`).then((response)=>{
        let audioMarks = response.data.audio_marks
        if (!audioMarks) { return }

        this.$once('song-loaded', ()=>{
          this.audioMarks = audioMarks
          for(let audioMark of this.audioMarks) {
            this.$nextTick(()=>{
              this.addAudioMark(audioMark)
            })
          }
        })
        // if (this.fileReady) {
        //   this.$emit('song-loaded')
        // }
      })
    },

    loopCurrentlyPlayedRegion() {
      if (!this.currentlyPlayedRegion) { return }
      let region = this.wavesurfer.regions.list[this.currentlyPlayedRegion.client_id]
      region.on('out', ()=>{
        if (this.loopCurrentlyPlayedRegion) {
          this.wavesurfer.play(region.start)
        }
      })

      if (this.currentlyPlayedRegion && this.loopCurrentlyPlayedRegion) {
        this.wavesurfer.play(this.currentlyPlayedRegion.start_seconds)
      }
    },

    pendingSelectedRegion() {
      if (!this.pendingSelectedRegion) { return }

      this.pendingSelectedRegion.on('out', ()=>{
        if (this.loopRegion) {
          this.wavesurfer.play(this.pendingSelectedRegion.start)
        }
      })
    },

    loopRegion() {
      if (this.pendingSelectedRegion && this.loopRegion) {
        this.wavesurfer.play(this.pendingSelectedRegion.start)
      }
    },

    blobUrl() {
      this.fileReady = false
      this.wavesurfer.cancelAjax()
      if (this.blobUrl) {
        document.body.classList.add('with-audio-player')
        this.wavesurfer.load(this.blobUrl)
      } else {
        document.body.classList.remove('with-audio-player')
      }
    },

    currentSeconds() {
      this.$emit('playback', {
        seconds: this.currentSeconds,
        formatted: this.readablePlayback
      })
    }
  },

  computed: {
    isTouchDevice() {
      return 'ontouchstart' in document.documentElement
    },

    timeLeft() {
      return this.duration - this.currentSeconds
    },

    readablePlayback() {
      return this.$options.filters.secondsToMinutes(this.currentSeconds)
    },

    readableRegion() {
      return [
        this.$options.filters.secondsToMinutes(this.pendingSelectedRegion.start),
        this.$options.filters.secondsToMinutes(this.pendingSelectedRegion.end)
      ].join(' - ')
    }
  },

  methods: {
    didRemoveAudioMark(removedAudioMark) {

      this.wavesurfer.regions.list[removedAudioMark.client_id].remove()

      let previous = this.audioMarks.length

      this.audioMarks = this.audioMarks.filter((audioMark)=>{
        return audioMark.client_id != removedAudioMark.client_id
      })

      if (previous == this.audioMarks.length) {
        //debugger
      }

      if (this.currentlyPlayedRegion && this.currentlyPlayedRegion.client_id) {
        this.currentlyPlayedRegion = null
      }
      if (this.selectedAudioMark && this.selectedAudioMark.id == removedAudioMark.id) {
        this.selectedAudioMark = null
      }
    },

    createRegion() {
      if (this.currentlyPlayedRegion) { return }

      let params = {}

      let regionEndSeconds = this.audioMarks.reverse().map( audioMark => audioMark.end_seconds )
      regionEndSeconds = regionEndSeconds.filter(seconds => seconds < this.currentSeconds)
      params.start_seconds = regionEndSeconds.length > 0 ? Math.max(...regionEndSeconds) : 0

      params.end_seconds = this.currentSeconds

      params.blob_sgid = this.blobSgid
      axios.post("/audio_marks", params).then((response)=>{
        if (!response.data.audio_mark) { return }
        let audioMark = response.data.audio_mark
        this.addAudioMark(audioMark)
        this.audioMarks.push(audioMark)
        this.selectedAudioMark = audioMark

        this.$nextTick(()=>{
          this.$refs.audioMark.focusInput()
        })
      })

    },

    getColor(index) {
      index = index % this.colorPalette.length
      return this.colorPalette[index]
    },

    close() {
      this.pause()
      this.removeRegion()
      this.$emit('playback', null)
      this.wavesurfer.regions.clear()
      this.blobUrl = null
      this.blobSgid = null
    },

    emitRegionUpdate(){
      this.$emit('region-updated', {
        start: this.pendingSelectedRegion.start,
        end: this.pendingSelectedRegion.end,
        formatted: this.readableRegion
      })
    },
    addAudioMark(audioMark, isPersisted=true) {
      if (audioMark.id) {
        this.$set(audioMark, 'client_id', `audio-mark-${this.clientRegionId}`)
        this.$set(audioMark, 'color', this.getColor(this.clientRegionId))
        this.clientRegionId += 1
      }

      let region = this.wavesurfer.addRegion({
        id: audioMark.client_id,
        start: audioMark.start_seconds,
        end: audioMark.end_seconds,
        color: audioMark.color
      })

      return region
    },
    applyOnLoadOptions() {
      if (this.onLoadOptions.start_seconds) {
        this.wavesurfer.play(this.onLoadOptions.start_seconds)
      } else {
        this.wavesurfer.play()
      }
      this.onLoadOptions = {}
    },

    removeRegion() {
      this.loopRegion = false
      if (!this.pendingSelectedRegion) { return }
      this.$emit('region-removed')
      this.pendingSelectedRegion.remove()
      this.pendingSelectedRegion = null
    },

    onNewSongLoaded() {
      this.duration = Math.ceil(this.wavesurfer.backend.getDuration())
      this.currentSeconds = 0
      this.$emit('song-loaded')
    },

    onAudioProcess() {
      this.currentSeconds = Math.ceil(this.wavesurfer.getCurrentTime())
    },

    createWaveSurfer() {
      this.wavesurfer = WaveSurfer.create({
        backend: 'MediaElement',
        container: `#waveform`,
        barWidth: 2,
        //splitChannels: true,
        closeAudioContext: true, // should cleanup
        responsive: 500, // 500ms debounced window resize callback

        plugins: [
          Regions.create({
            dragSelection: {
              slop: 5
            }
          })
        ]
      })
      window.wavesurfer = this.wavesurfer

      //this.wavesurfer.setVolume(0.001)
      //this.wavesurfer.setVolume(0.8)

      this.wavesurfer.on('pause', ()=>{
        this.$emit('pause')
        this.isPlaying = false
      })

      this.wavesurfer.on('seek', (progress)=>{
        // handle region-in and region-out
        this.currentSeconds = Math.round(this.duration * progress)

        setTimeout(()=>{
          this.selectedAudioMark = this.currentlyPlayedRegion
        }, 100)
      })

      this.wavesurfer.on('play', ()=>{
        this.$emit('play')
        this.isPlaying = true
      })

      this.wavesurfer.on('ready', ()=>{
        this.fileReady = true
        this.onNewSongLoaded()
        this.applyOnLoadOptions()
      })

      this.wavesurfer.on('region-in', (region)=>{
        if (region.id.indexOf('audio-mark') < 0) { return }
        this.$nextTick(()=>{
          this.currentlyPlayedRegion = this.audioMarks.find((mark)=>{
            return mark.client_id == region.id
          })
        })
      })

      this.wavesurfer.on('region-click', (region)=>{
        if (region.id.indexOf('audio-mark') < 0) {
          return
        }
        if (this.loopCurrentlyPlayedRegion) { return }
        setTimeout(()=>{
          this.selectedAudioMark = this.audioMarks.find((mark)=>{
            return mark.client_id == region.id
          })
        }, 200)
      })

      this.wavesurfer.on('region-out', (region)=>{
        this.currentlyPlayedRegion = null
      })

      this.wavesurfer.on('region-created', (region)=>{
        if (region.id.indexOf('audio-mark') >= 0) { return }
        this.removeRegion()
        this.pendingSelectedRegion = region
      })

      this.wavesurfer.on('region-update-end', (region)=>{
        if (region.id.indexOf('audio-mark') >= 0) { return }
        this.pendingSelectedRegion = region
        this.emitRegionUpdate()
      })

      this.wavesurfer.on('audioprocess', _.throttle(()=>{
        this.onAudioProcess()
      }, 200))
    },

    rewind(time=10) {
      this.wavesurfer.skip(-time)
    },

    play() {
      this.wavesurfer.play()
    },

    pause() {
      this.wavesurfer.pause()
    },

    playPause() {
      if (this.isPlaying) {
        this.pause()
      } else {
        this.play()
      }
    },

    fastForward(time=10) {
      this.wavesurfer.skip(time)
    }

  },

  filters: {
    secondsToMinutes(seconds) {
      let minutes = Math.floor(seconds / 60)
      seconds = Math.floor(seconds % 60)

      if (seconds < 10) { seconds = `0${seconds}` }

      return `${minutes}:${seconds}`
    }
  },

  beforeDestroy() {
    if (this.wavesurfer) {
      this.wavesurfer.destroy()
    }
  }

}
</script>

<template>

  <div class="audio-player" :class="{'is-hidden': !blobUrl}">
    <b-button @click="close" class="chevron" size="is-large" icon-left="chevron-double-down"></b-button>
    <div class="region-controls column">
      <div class="columns is-mobile">
        <div class="column">
          <div class="columns">
            <div class="column is-narrow">
              <b-field>
                <div class="control">
                  <b-button @click="showRegions = !showRegions" :icon-left="showRegions ? 'eye' : 'eye-outline'"></b-button>
                </div>

                <div class="control">
                  <b-button @click="showRegionEdits = !showRegionEdits" :type="showRegionEdits ? 'is-info' : ''" icon-left="pen"></b-button>
                </div>

                <p class="control">
                  <b-button :disabled="!currentlyPlayedRegion" @click="loopCurrentlyPlayedRegion = !loopCurrentlyPlayedRegion" :type="{'is-info': loopCurrentlyPlayedRegion}" icon-left="repeat"></b-button>
                </p>
              </b-field>
            </div>

            <div class="column is-narrow">
              <div class="buttons">
                <b-button v-if="showRegions && showRegionEdits" icon-left="select-compare" @click="createRegion" :disabled="currentlyPlayedRegion" :loading="audioMarkIsLoading">Add region</b-button>

                <b-button v-if="blobUrl" icon-left="download" tag="a" :href="blobUrl" target="_blank">Download</b-button>
              </div>
            </div>

            <div class="column is-narrow" v-show="showRegions && showRegionEdits && selectedAudioMark">
              <edit-audio-mark ref="audioMark" v-if="selectedAudioMark" :blobSgid="blobSgid" @destroy="didRemoveAudioMark" v-model="selectedAudioMark"></edit-audio-mark>
            </div>

          </div>
        </div>
        <div class="column"  v-if="pendingSelectedRegion">
          <div class="columns">
            <div class="column has-text-right">
              {{ readableRegion }}
            </div>
            <div class="column is-narrow">
              <b-button @click="loopRegion = !loopRegion" :type="{'is-info': loopRegion}" rounded icon-left="repeat"></b-button>

              <b-button @click="removeRegion" rounded icon-left="delete"></b-button>
            </div>
          </div>
        </div>
      </div>

    </div>


    <div class="is-relative">
      <div id="waveform"></div>
    </div>
    <b-loading :is-full-page="false" :active="!fileReady"></b-loading>

    <div class="timeline columns is-mobile is-centered">
      <div class="time column is-narrow has-text-left">
        {{ readablePlayback }}
      </div>
      <div class="column has-text-centered">
        <div class="song-title">
          <span :class="{'is-size-7': currentlyPlayedRegion && currentlyPlayedRegion.title}">{{ blobName }}</span>
          <span @click="selectedAudioMark = currentlyPlayedRegion" v-if="currentlyPlayedRegion && showRegions" :style="{'background-color': currentlyPlayedRegion.color}">
            {{ currentlyPlayedRegion.title }}
          </span>
        </div>
      </div>
      <div class="time column is-narrow has-text-right">
        -{{ timeLeft | secondsToMinutes }}
      </div>
    </div>

    <div class="controls columns is-mobile is-centered">

      <div class="column has-text-right">
        <b-button @click="rewind(10)" :disabled="!fileReady" type="is-info" rounded icon-left="rewind-10" size="is-large"></b-button>
      </div>

      <div class="column has-text-centered">
        <b-button v-if="isPlaying" :disabled="!fileReady" @click="pause" type="is-info" rounded icon-left="pause" size="is-large"></b-button>
        <b-button v-else :disabled="!fileReady" @click="play" type="is-info" rounded icon-left="play" size="is-large"></b-button>
      </div>

      <div class="column has-text-left">
        <b-button @click="fastForward(10)" :disabled="!fileReady" type="is-info" rounded icon-left="fast-forward-10" size="is-large"></b-button>
      </div>

    </div>

  </div>

</template>

<style lang="scss">
#waveform {
  background-color: #f2f2f2;
}
.audio-player {
  .chevron {
    position: absolute;
    left: 0;
    bottom: 100%;
    background-color: #fafafa;
    margin-bottom: -1px;
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 3px -3px 4px #ccc;
  }
  #waveform {
    border-bottom: 1px solid #ddd;
  }
  .song-title {
    font-size: 1.2rem;
    color: black;
  }
  .time{
    min-width: 70px;
  }
  .controls {
    padding: 30px;
    padding-top: 0;
  }
  .timeline {
    padding: 10px;
    padding-bottom: 0;
    color: #999;
    .column {
      padding-bottom: 0.2rem;
    }
  }
  .region-controls {
    background-color: #fafafa;
    padding-top: 8px;
    margin-bottom: 0;
    color: #999;
  }
  position: fixed;
  width: 100%;
  background-color: white;
  bottom: 0;
  left: 0;
  border-top: 1px solid #bbb;
  box-shadow: 0 -6px 8px #ccc;
}
</style>
