<script>
import WaveSurfer from "wavesurfer.js";

export default {
  props: {
    url: {},
    options:{}
  }
}
</script>

<template>
  <div>
    {{ options }}
  </div>

    <!-- <div :id="waveformId"></div> -->
    <!-- <a @click="wavesurfer.playPause()">PLAY / PAUSE</a> -->

</template>

<style lang="scss">

</style>