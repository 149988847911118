let keyMapping = {
  13: 'enter',
  27: 'esc',

  8: 'backspace',
  32: 'space',

  37: 'left',
  38: 'up',
  39: 'right',
  40: 'down',

  65: 'a',
  66: 'b',
  67: 'c',
  68: 'd',
  69: 'e',
  70: 'f',
  71: 'g',
  72: 'h',
  73: 'i',
  74: 'j',
  75: 'k',
  76: 'l',
  77: 'm',
  78: 'n',
  79: 'o',
  80: 'p',
  81: 'q',
  82: 'r',
  83: 's',
  84: 't',
  85: 'u',
  86: 'v',
  87: 'w',
  88: 'x',
  89: 'y',
  90: 'z',
}

export default {

  created() {
    if (!this.$options.keyboard) { return }

    if (this.$options.keyboard.up) {
      document.addEventListener('keyup', this.handleKeyUp)
    }
    if (this.$options.keyboard.down || this.$options.keyboard.force) {
      document.addEventListener('keydown', this.handleKeyDown)
    }
  },

  beforeDestroy () {
    if (!this.$options.keyboard) { return }
    document.removeEventListener('keyup', this.handleKeyUp)
    document.removeEventListener('keydown', this.handleKeyDown)
  },

  methods: {
    handleKeyUp(e) {
      if (!this.shouldTrigger()) { return }
      if (keyMapping[e.keyCode] && this.$options.keyboard.up[keyMapping[e.keyCode]]) {
        this.warnIfUnknownActiveElement(e)
        this.$options.keyboard.up[keyMapping[e.keyCode]].call(this, e)
      }
    },

    handleKeyDown(e) {
      if (keyMapping[e.keyCode] && this.$options.keyboard.force && this.$options.keyboard.force[keyMapping[e.keyCode]]) {
        this.$options.keyboard.force[keyMapping[e.keyCode]].call(this, e)
      }

      if (!this.shouldTrigger()) { return }

      if (keyMapping[e.keyCode] && this.$options.keyboard.down && this.$options.keyboard.down[keyMapping[e.keyCode]]) {
        this.warnIfUnknownActiveElement(e)
        this.$options.keyboard.down[keyMapping[e.keyCode]].call(this, e)
      }
    },

    warnIfUnknownActiveElement(e) {
      if (document.activeElement && document.activeElement.type) {
        console.warn('emmiting with activeElement of type', document.activeElement.type, keyMapping[e.keyCode])
      }
    },

    hasActiveElement() {
      return document.activeElement && (document.activeElement.type == 'input' || document.activeElement.type == 'text' || document.activeElement.type == 'textarea' || document.activeElement.type == 'select')
    },

    shouldTrigger() {
      let shouldRun = !this.hasActiveElement()
      if (this.$options.keyboard.if) {
        shouldRun = shouldRun && this.$options.keyboard.if.call(this)
      }
      return shouldRun
    }

  }

}
