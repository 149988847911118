<script>
import * as Trix from "trix"
import axios from "axios"

export default {

  props: {
    editor: {} // css selector
  },

  data() {
    return {
      singlePosition: null,
      regionPosition: null,
      regions: null,

      selectedRegion: null,

      currentPositionHref: null
    }
  },

  mounted() {
    if (this.audioPlayer) {
      this.audioPlayer.addEventListener('play', this.onPlay)
      this.audioPlayer.addEventListener('pause', this.onPause)
      this.audioPlayer.addEventListener('playback', this.onPlayback)
      this.audioPlayer.addEventListener('audio-marks-loaded', this.onAudioMarksLoaded)
      this.audioPlayer.addEventListener('region-updated', this.onRegionUpdated)
      this.audioPlayer.addEventListener('region-removed', this.onRegionRemoved)
    }
  },

  beforeDestroy() {
    if (this.audioPlayer) {
      this.audioPlayer.removeEventListener('playback', this.onPlayback)
      this.audioPlayer.removeEventListener('audio-marks-loaded', this.onAudioMarksLoaded)
      this.audioPlayer.removeEventListener('region-updated', this.onRegionUpdated)
      this.audioPlayer.removeEventListener('region-removed', this.onRegionRemoved)
    }
  },

  computed: {
    audioPlayer() {
      return document.querySelector('audio-player')
    },

    blobSgid() {
      return this.audioPlayer.getVueInstance().blobSgid
    }
  },

  watch: {
    regions() {
      if (this.regions && !this.selectedRegion) {
        this.selectedRegion = this.regions[0]
      }
    }
  },

  methods: {

    onPlay() {
      this.currentPositionHref = null
    },

    onPause() {
      if (!this.singlePosition) { return }

      this.currentPositionHref = `${window.Song.url}?blob_sgid=${this.blobSgid}&pos=${this.singlePosition.seconds}`
    },

    onPlayback(event) {
      if (event.detail) {
        this.singlePosition = event.detail[0]
      } else {
        this.singlePosition = null
      }
    },
    onAudioMarksLoaded(event) {
      this.regions = event.detail[0]
    },
    onRegionUpdated(event) {
      this.regionPosition = event.detail[0]
    },
    onRegionRemoved(event) {
      this.regionPosition = null
    },

    insertAudioMark() {
      var attachment = this.buildAttachment(this.selectedRegion.title)
      this.attach(attachment, {id: this.selectedRegion.id})
    },

    insertRegion() {
      var attachment = this.buildAttachment(this.regionPosition.formatted)
      this.attach(attachment, {start_seconds: this.regionPosition.start, end_seconds: this.regionPosition.end})
    },

    insertPosition(event) {
      event.preventDefault()
      var attachment = this.buildAttachment(this.singlePosition.formatted)
      this.attach(attachment, {start_seconds: this.singlePosition.seconds})
    },

    buildAttachment(content) {
      return new Trix.Attachment({
        content: `<span class="attached-url">${content}</span>`,
      })
    },

    attach(attachment, params) {
      this.addAttachment(attachment)
      params.blob_sgid = this.blobSgid
      params.authenticity_token = this.authenticityToken()
      axios.post("/audio_marks/attach", params).then((response)=>{
        if (response.data.attachment) {
          attachment.setAttributes(response.data.attachment)
        }
      })
    },

    addAttachment(attachment) {
      let editor = document.querySelector(this.editor).querySelector('trix-editor').editor
      editor.insertAttachment(attachment)
      setTimeout(()=>{
        editor.moveCursorInDirection('forward')
        editor.moveCursorInDirection('forward')
      }, 10)
    },

    authenticityToken() {
      return document.head.querySelector('[name="csrf-token"]').content
    }
  }
}
</script>

<template>
  <div class="column is-12" v-if="singlePosition || regions">
    <b-field grouped>
      <!-- <b-button @click="insertRegion" v-if="regionPosition" icon-left="link-variant">{{ regionPosition.formatted }}</b-button> -->
      <b-field>
        <a class="button" :href="currentPositionHref" @click="insertPosition" v-if="singlePosition">
        <span class="icon-is-small">
          <i class="mdi mdi-link-variant"></i>
        </span>
        <span>{{ singlePosition.formatted }}</span>
      </a>
      </b-field>
      <b-field v-if="regions">
        <p class="control">
          <b-button icon-left="link-variant" @click="insertAudioMark"></b-button>
        </p>
        <p class="control">
          <b-select v-model="selectedRegion">
            <option v-for="region in regions" :key="region.sgid" :value="region">
              {{ region.label }}
            </option>
          </b-select>
        </p>
      </b-field>
    </b-field>
  </div>
</template>

<style lang="scss">

</style>
