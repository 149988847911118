<script>

import AudioPreview from './AudioPreview.vue'
import ImagePreview from './ImagePreview.vue'
import GuitarTab from './GuitarTab.vue'

export default {

  components: {
    AudioPreview,
    ImagePreview,
    GuitarTab
  },

  props: {
    byte_size: {},
    label: {},
    filename: {},
    content_type: {},
    extension: {},
    url: {},
    blob_sgid: {},
    start_seconds: {},
    end_seconds: {}
  },

  computed: {
    type() {
      return this.content_type.split('/')[0]
    },

    previewComponent() {
      return `${this.type}-preview`
    }
  }

}
</script>

<template>

  <action-text-attachment v-if="content_type == 'application/guitartab'">
    <guitar-tab :url="url" :options="$props" class="live-preview">
    </guitar-tab>
  </action-text-attachment>
  <action-text-attachment v-else-if="type == 'audio'">
    <component :is="previewComponent" :url="url" :options="$props" class="live-preview">
    </component>
  </action-text-attachment>
  <div v-else>
    <slot></slot>
  </div>

</template>

<style lang="scss">
.live-preview {
  display: inline-block;
  position: relative;
  height: auto;
  padding: 2px 10px;
}
</style>