<script>
import axios from 'axios'
import Keyboard from './Keyboard.js'
export default {
  mixins: [Keyboard],

  props: {
    value: {},

    blobSgid: {}
  },

  data() {
    return {
      audioMark: this.value,
      isLoading: false,
      isDirty: false,
      showSuccess: false
    }
  },

  watch: {
    value() {
      this.audioMark = this.value
    },
    'audioMark.title'() {
      this.isDirty = true
    },

    // audioMark: {
    //   immediate: true,
    //   handler() {
    //     setTimeout(()=>{
    //       //this.focusInput()
    //     }, 0)
    //   }
    // },

    isDirty() {
      if (this.isDirty) {
        this.showSuccess = false
      }
    },
  },

  computed: {
    isTouchDevice() {
      return 'ontouchstart' in document.documentElement
    },

    authenticityToken() {
      return document.head.querySelector('[name="csrf-token"]').content
    },
  },

  keyboard: {
    if() {
      return !this.isLoading && this.audioMark
    },
    up: {
      //backspace() { this.deleteAudioMark() },
    },
    force: {
      esc() { this.blurInput() }
    }
  },

  methods: {
    focusInput() {
      if (this.isTouchDevice) { return }
      this.$refs.input.focus()
    },

    blurInput() {
      this.$refs.input.$el.querySelector('input').blur()
    },

    deleteAudioMark() {
      if (!confirm('Delete region?')) { return }

      this.isLoading = true
      let params = {authenticity_token: this.authenticityToken}
      axios.delete(`/audio_marks/${this.audioMark.id }`, {params: params}).then(()=>{
        this.$emit('destroy', this.audioMark)
      }).catch(()=>{
        this.isLoading = false
      })
    },

    saveAudioMark() {
      if (!this.isDirty) { return }

      let params = Object.assign({}, this.audioMark)
      params.blob_sgid = this.blobSgid
      params.authenticity_token = this.authenticityToken
      this.isLoading = true
      axios.patch(`/audio_marks/${this.audioMark.id }`, params).then((response)=>{
        this.isLoading = false
        if (response.data.audio_mark) {
          let clientId = this.audioMark.client_id
          let color = this.audioMark.color
          this.$nextTick(()=>{
            this.isDirty = false
          })
          this.audioMark = response.data.audio_mark
          this.audioMark.client_id = clientId
          this.audioMark.color = color
          this.$emit('input', this.audioMark)

          setTimeout(()=>{
            this.blurInput()
          }, 200)

          this.showSuccess = true
          setTimeout(()=>{
            this.showSuccess = false
          }, 4000)
        }
      }).catch(()=>{
        this.isLoading = false
      })
    }
  }
}
</script>

<template>
  <b-field v-if="audioMark">
    <span class="bar" :style="{'background-color': audioMark.color}"></span>
    <form @submit.prevent="saveAudioMark" class="control">
      <b-input type="text" placeholder="Enter name" v-model="audioMark.title" ref="input"></b-input>
    </form>
    <p class="control">
      <b-button @click="deleteAudioMark" icon-left="delete" :loading="isLoading"></b-button>
    </p>
    <p class="control" v-if="showSuccess || isLoading || isDirty">
      <b-button v-show="showSuccess" type="is-success" disabled icon-left="check"></b-button>
      <b-button @click="saveAudioMark" v-show="isLoading || isDirty" :disabled="!isDirty" :loading="isLoading">Save</b-button>
    </p>
  </b-field>
</template>

<style lang="scss">
.bar {
  width: 10px;
  display: inline-block;
}
</style>