<script>

export default {
  props: {
    url: {},
    options: {}
  },

  methods: {
    playAudio() {
      window.bus.$emit('audio.play', {
        url: this.url,
        blob_sgid: this.options.blob_sgid,
        name: this.options.filename,
        start_seconds: this.options.start_seconds,
        end_seconds: this.options.end_seconds
      })
    }
  }
}
</script>

<template>

  <b-button @click="playAudio" icon-right="play-circle-outline">
    <span class="is-size-7">{{ options.filename }}</span> &middot; {{ options.label }}
  </b-button>

</template>

<style lang="scss">
</style>